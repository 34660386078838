<template>
  <b-row class="match-height">
    <b-col lg="6"> <b-card
    title="Change Password"
  >
    <b-form
        @submit.prevent="changePassowrd">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Old Password *"
            label-for="old_password"
            label-cols-md="4"
          >
            <b-form-input
              id="old_password"
              placeholder="Type Your Old Password"
              required="required"
              type="password"
              :ripple="false"
              v-model="OldPassword"
            />
          </b-form-group>
          <b-form-group
            label="New Password *"
            label-for="new_password"
            label-cols-md="4"
          >
            <b-form-input
              id="new_password"
              placeholder="Type Your New Password"
              required="required"
              type="password"
              :ripple="false"
              v-model="NewPassword"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>

    </b-form>

  </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'

const modalShow = false
const OldPassword = ''
const NewPassword = ''
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  data() {
    return {
    modalShow,
    OldPassword,
    NewPassword,
    }
  },
  directives: {
    Ripple,
  },
  methods: {
  changePassowrd() {
    const userToken = localStorage.getItem('userToken')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    console.log('headers', headers)
      const body = {
        OldPassword: this.OldPassword,
        NewPassword: this.NewPassword,
      }
    axios
      .post('https://api.geolims.com/user_service/changepassword', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
          this.modalShow = true
          this.$router.replace({ name: 'auth-login' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Change Password Success, Please Sign in again',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Change Password Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Change Password Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  },
}
</script>
